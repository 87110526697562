/*  ==========================================================================
    VARIABLES
    Collection of all variables

    INFO:
    - try to use variables as much as possible, it makes life easier
    ========================================================================== */

/*
#
# COLORS
#
*/

// main colors
$clr_white: #fff;
$clr_black: #000;
$clr_brown: #4B4336;
$clr_gray_fliestext: #6A6A6A;
$clr_gray_button: #B4B0AB;
$clr_gray_dark: #3F4B50;
$clr_gold: #B39A57;

// custom colors
$clr_cm_bgmenu: #383838;
$clr_cm_offerlist: #F2F2F1;
$clr_cm_offerlistIcon_roomList: #E5E3E1;

$clr_cm_headernav: $clr_gray_button;
$clr_cm_textimage_box: #E9EAE4;


/*
#
# FONTS
#
*/

// font families
$ff_montserrat: 'Montserrat', sans-serif;

// font weights
$fw_light: 300;
$fw_regular: 400;
$fw_medium: 500;
$fw_semiBold: 600;
$fw_bold: 700;

/*
#
# TRANSITIONS
#
*/

// transition speed
$ts_slow: 1s;
$ts_middle: 0.6s;
$ts_fast: 0.3s;
$ts_superfast: 0.2s;

//custom speeds
$ts_cm_headermenu: 0.3s;



/*
#
# CUSTOM VARS
#
*/

// footer row_1 border
$footer_info_border: 1px solid $clr_white;

// Icomoon icons content
$icon_weiterPfeil: '\e930';
$icon_imageGallery: '\e90f';
$icon_drop_down_pfeil_unten: '\e90d';
$icon_slider_pfeil_links: '\e925';
$icon_slider_pfeil_rechts: '\e926';
$icon_signet: '\e91f';
$icon_zoom: '\e937';
$icon_angebotsuebersicht: '\e908';
$icon_berg: '\e900';

// class_name
$class_loader: loader;