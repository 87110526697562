/*  ==========================================================================
    GENERAL
    ========================================================================== */

.ehotelier_element-CE {
  // price box
  .price_box {
    min-width: 125px;
    height: 125px;
    background-color: $clr_brown;
    color: $clr_white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.1;
    padding: 15px;

    span {
      display: block;

      &.price {
        font-size: 30px;
      }
    }

    &.revert {
      background-color: $clr_white;
      color: $clr_brown;
    }
  }

  // switch boxes
  .switch_boxes {
    .switch_box {
      display: flex;
      margin-top: 40px;
      position: relative;

      > div {
        width: 50%;
        padding-top: 45px;
      }
      .switch_box_background {
        position: absolute;
        right: 0;
        top: 0;
        width: 60%;
        height: calc(100% - 45px);
        background-color: $clr_brown;
        padding: 0;
      }
      .switch_box_image {
        position: relative;
        padding-right: 10px;

        .image_preview_slider {
          .image_preview {
            height: 0;
            padding-bottom: 57%;
            position: relative;

            img {
              @include coverImgV2();
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
        .price_box {
          position: absolute;
          left: 35px;
          top: 0;
        }
      }
      .switch_box_text {
        position: relative;
        padding: 45px 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .button_row {
          display: flex;
          padding: 40px 0;
          flex-wrap: wrap;

          .button {
            margin: 10px 10px 0 0;

            &:nth-child(1) {
              @include normalButton($clr_white, $clr_brown, transparent, true, true);
            }
            &:nth-child(2) {
              @include normalButton($clr_gray_button, $clr_white, $clr_gold, false, true);
              margin-right: 0;
            }
          }
        }
      }

      &:nth-child(2n) {
        flex-direction: row-reverse;

        .switch_box_background {
          right: auto;
          left: 0;
        }
        .switch_box_image {
          padding-right: 0;
          padding-left: 10px;

          .price_box {
            right: 35px;
            left: auto;
          }
        }

      }
    }
  }

  // detail_popups
  .service_detail_popup {
    position: fixed;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    z-index: 999;
    overflow-y: auto;
    padding: 5% 0 0;
    background-color: transparent;
    transition: top $ts_middle linear $ts_middle, background-color $ts_middle linear 0s;

    .detail_popup_item {
      background-color: $clr_white;
      padding-top: 55px;
      display: none;
      user-select: text;


      .detail_closer {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        align-items: center;
        padding-bottom: 15px;
        text-transform: uppercase;
        cursor: pointer;

        .icon {
          font-size: 10px;
          color: $clr_brown;
          margin-left: 10px;
        }

        &.detail_closer_bottom {
          justify-content: center;
        }
        &:hover {
          color: $clr_gold;

          .icon {
            color: inherit;
          }
        }
      }

      .detail_top {
        max-width: 1250px;

        .detail_images {
          position: relative;

          .image_slider {
            @include clearfix();
            
            .image_item {
              float: left;
              height: 0;
              padding-bottom: 49%;
              overflow: hidden;

              img {
                @include coverImgV2();
                height: auto;
              }
            }
          }
          .image_text_content {
            pointer-events: none;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, rgba($clr_black, 0.7), transparent);
            color: $clr_white;
            padding: 25px 0;
            //min-height: 50%;
            display: flex;
            align-items: flex-end;

            .image_text_wrapper {
              width: 100%;
              margin: 0 auto;
            }
          }
        }
      }
      .detail_bottom {
        .detail_text {
          font-weight: $fw_regular;
        }
        .button_row {
          display: flex;
          justify-content: center;
          padding: 40px 0;

          .button {

            &:nth-child(1) {
              @include normalButton($clr_gray_button, $clr_white, $clr_brown, false, true);
            }
            &:nth-child(2) {
              margin-left: 10px;
              @include normalButton($clr_gold, $clr_white, $clr_brown, false, true);
            }
          }
        }
      }

      &.active {
        display: block;
      }
    }

    .detail_popup_slider {
      box-shadow: 2px 2px 6px 2px rgba($clr_black, 0.2);

      > .slider_arrow {
        pointer-events: none;

        &.slider_arrow_prev {
          @include sliderArrowAbsolute(true, $clr_black, $clr_gold, 40px);

          position: fixed;
          width: 100%;
          max-width: 1600px;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 0;
          display: none;
          justify-content: flex-start;
        }
        &.slider_arrow_next {
          @include sliderArrowAbsolute(false, $clr_black, $clr_gold, 40px);

          position: fixed;
          width: 100%;
          max-width: 1600px;
          right: auto;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 0;
          display: none;
          justify-content: flex-end;
        }

        &:before {
          pointer-events: all;
          padding: 1%;
        }
      }
    }

    &.detail_opened {
      top: 0;
      background-color: rgba($clr_gray_button, 0.5);
      transition: top $ts_middle linear 0s, background-color $ts_middle linear $ts_middle;

      .detail_popup_slider > .slider_arrow {
        &.slider_arrow_prev,
        &.slider_arrow_next {
          display: flex;
        }
      }
    }
  }

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 1649px) {
    .service_detail_popup .detail_popup_slider > .slider_arrow {
      &.slider_arrow_prev,
      &.slider_arrow_next {
        transform: translate(0);
        top: auto;
        bottom: 10px;
        width: 50px;
        height: 50px;
        font-size: 35px;
        background-color: $clr_white;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 3px $clr_brown;
        pointer-events: all;
        right: 20px;
        left: auto;
        margin: 0;
      }

      &.slider_arrow_prev {
        bottom: 10px;
      }
      &.slider_arrow_next {
        bottom: 70px;
      }
    }
  }
  @media (max-width: 999px) {
    .price_box {
      min-width: 100px;
      height: 100px;
      font-size: 12px;

      span.price {
        font-size: 20px;
        padding: 5px 0;
      }
    }
    .switch_boxes {
      .switch_box {
        .switch_box_image {
          padding-top: 25px;
        }
        .switch_box_background {
          height: 100%;
        }
        .switch_box_text {
          padding: 25px 15px;

          .button_row {
            padding: 10px 0 0 0;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .price_box {
      min-width: 140px;
      height: 80px;
    }
    .switch_boxes {
      .switch_box {
        display: block;
        margin-top: 15px;

        .switch_box_image {
          padding: 0!important;
          width: 100%;

          .price_box {
            position: relative;
            margin: -30px auto 0;
            left: auto!important;
            right: auto!important;
            display: table;
          }
        }
        .switch_box_background {
          width: 100%;
        }
        .switch_box_text {
          width: 100%;
          padding: 15px;

          .button_row {
            justify-content: center;
          }
        }
      }
    }
    .service_detail_popup {
      .detail_popup_wrapper {
        padding: 0 5px;
      }
      .detail_popup_item {
        padding-top: 20px;

        .detail_top .detail_images {
          display: flex;
          flex-direction: column-reverse;

          .image_text_content {
            position: relative;
            color: $clr_brown;
            background: none;
            padding: 0 0 10px 0;

            .image_text_wrapper {
              padding: 0;
            }
          }
        }
        .detail_bottom .button_row {
          padding: 15px;
          flex-wrap: wrap;

          .button {
            margin: 10px!important;
          }
        }
      }
      .detail_popup_slider > .slider_arrow {
        &.slider_arrow_prev,
        &.slider_arrow_next {
          width: 30px;
          height: 30px;
          font-size: 20px;
        }
        &.slider_arrow_next {
          bottom: 50px;
        }

      }
    }
  }
}

/*  ==========================================================================
    OFFER
    ========================================================================== */

// OFFER LIST-VIEW
.offer_list-view-EH {
  .season_offer_switcher {
    display: flex;
    justify-content: center;

    .switcher_item {
      background-color: $clr_white;
      color: $clr_gray_button;
      width: 50%;
      max-width: 335px;
      height: 100%;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
      padding: 20px;
      text-transform: uppercase;
      box-shadow: inset 0 0 0 2px $clr_gray_button;
      cursor: pointer;
      font-weight: $fw_regular;
      margin-right: 15px;

      .icon {
        font-size: 22px;
        margin-right: 10px;
      }

      &.active {
        background-color: $clr_gray_button;
        color: $clr_white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .list {
    .item {

      .offer_background {
        background-color: $clr_cm_offerlist;
        overflow: hidden;

        .icon {
          position: absolute;
          bottom: 0;
          right: 40%;
          font-size: 210px;
          color: $clr_cm_offerlistIcon_roomList;
        }
      }
      .offer_text {
        .top_text {

          .title {
            font-size: 25px;
            margin-bottom: 15px;
            font-weight: $fw_light;
          }
          .date_nights {
            font-size: 20px;
            margin-bottom: 15px;
            font-weight: $fw_medium;

            .dates {
              span {
                white-space: nowrap;

                &:after {
                  content: ' | ';
                  display: inline;
                }
                &:last-child:after {
                  display: none;
                }
              }
            }
            .nights {
              text-transform: uppercase;
            }
          }
        }
      }

      &.disabled {
        display: none!important;
      }
    }
  }
  .details {

    .detail_popup_item {
      position: relative;

      .detail_top {

        .detail_images {

          .image_text_content {
            .price_pp {
              font-size: 17px;
              padding-bottom: 25px;
              line-height: 1.2;

              .price {
                font-size: 35px;
                font-weight: $fw_medium;
              }
            }
            .title {
              font-size: 35px;
              text-transform: uppercase;
              font-weight: $fw_light;
            }
          }
        }
      }
      .detail_text {
        padding-top: 35px;

        .date_nights {
          font-size: 1.3em;
          font-weight: $fw_medium;
          margin-bottom: 1em;

          .icon {
            font-size: 18px;
            width: 30px;
            display: inline-block;

            &:after {
              display: none!important;
            }
          }

          .dates {
            display: flex;
            align-items: center;

            span {
              white-space: nowrap;

              &:after {
                content: ' | ';
                display: inline;
              }
              &:last-child:after {
                display: none;
              }
            }
          }
          .nights {
            display: flex;
            align-items: center;
            text-transform: uppercase;
          }
        }
      }
      &:before {
        @include font_icomoon();
        content: $icon_signet;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 40px;
        color: $clr_brown;
        font-size: 20px;
        pointer-events: none;
      }
    }
  }
  .bottom_more_button {
    color: $clr_brown;
    text-align: center;
    font-weight: $fw_medium;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .icon {
      font-size: 0.8em;
      margin-left: 10px;
    }
    &:hover {
      color: $clr_gold;
    }
  }
  .no_filtered_content {
    text-align: center;
    padding-top: 20px;
  }

  //.bn_offer_list-view:not(.all_offers_opened) {
  //  .bottom_more_button {
  //    display: flex;
  //  }
  //  .list .item:nth-child(n+5) {
  //    display: none;
  //  }
  //}

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 999px) {
    .list .item .offer_text .top_text .title {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .list .item .offer_text .top_text .date_nights {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .details .detail_popup_item .detail_top .detail_images .image_text_content .price_pp {
      font-size: 14px;
      padding-bottom: 15px;
    }
    .details .detail_popup_item .detail_top .detail_images .image_text_content .price_pp .price {
      font-size: 25px;
    }
    .details .detail_popup_item .detail_top .detail_images .image_text_content .title {
      font-size: 25px;
    }
  }
  @media (max-width: 767px) {
    .season_offer_switcher .switcher_item {
      flex-direction: column;
      padding: 10px;
      min-height: 0;
      margin-right: 5px;

      .icon {
        margin: 0 0 10px 0;
      }
    }
    .list .item .offer_background .icon {
      font-size: 100px;
      right: auto;
      left: -50px;
    }
    .list .item:nth-child(2n) .offer_background .icon {
      right: -50px;
      left: auto;
    }

    .details .detail_popup_item:before {
      top: 20px;
      font-size: 15px;
      left: 15px;
      transform: none;
    }
    .details .detail_popup_item .detail_top .detail_images .image_text_content .title {
      font-size: 18px;
    }
    .details .detail_popup_item .detail_top .detail_images .image_text_content .price_pp {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      padding: 10px 15px;
    }
    .details .detail_popup_item .detail_text {
      padding-top: 15px;
    }
    .details .detail_popup_item .detail_text .date_nights {
      font-size: 1em;
    }
    .details .detail_popup_item .detail_text .date_nights .dates {
      padding-bottom: 5px;
    }
  }
}
// OFFER TEASER
.offer_teaser-EH {
  overflow-x: hidden;

  .center_wrapper {
    max-width: 1920px;
    overflow: hidden;
  }
  .slider_wrapper {
    max-width: 1180px;
    margin: auto;
    padding-top: 60px;
  }
  .slider {

    .item {

      .item_wrapper {
        display: flex;
        flex-direction: row-reverse;
        user-select: text;

        .offer_image {
          position: relative;
          width: 50%;
          z-index: 1;

          .offer_image_item {
            position: relative;
            height: 100%;
            min-height: 390px;

            img {
              @include coverImgV2();
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }
          }
          .price_box {
            position: absolute;
            right: 125px;
            top: -60px;
          }
          .offer_number {
            display: none;
            position: absolute;
            left: 20px;
            top: -45px;
            font-size: 95px;
            color: $clr_brown;
            line-height: 1;
          }
        }
        .offer_text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0 30px;
          position: relative;
          overflow: hidden;
          width: 50%;

          .top_text {
            position: relative;

            .top_offer_text {
              text-transform: uppercase;
              color: $clr_brown;
              font-size: 14px;
              padding-bottom: 5px;
            }
            .title {
              font-size: 25px;
              text-transform: uppercase;
              color: $clr_brown;
            }
            .date_nights {
              font-size: 20px;
              margin: 10px 0;

              .nights {
                //text-transform: uppercase;
              }
              span {
                display: block;
              }
            }
            .description {
              font-size: 16px;
              margin: 1em 0;
            }
          }
          .button_row {
            display: flex;
            margin-top: 15px;

            .button {
                @include normalButton($clr_gray_button, $clr_white, $clr_brown, false, false);
            }
          }
        }
      }
    }

    .slider_arrow {
      &.slider_arrow_prev {
        @include sliderArrowAbsolute(true, $clr_brown, $clr_gold, -65px);
      }
      &.slider_arrow_next {
        @include sliderArrowAbsolute(false, $clr_brown, $clr_gold, -65px);
      }
    }

  }
  .bottom_more_link {
    @include more_icon_link($clr_gray_fliestext, $clr_gray_button, $icon_angebotsuebersicht);
  }

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 1599px) {
    .slider .item .item_wrapper .offer_image .price_box {
      right: 45px;
    }
    .slider .slider_arrow {
      &.slider_arrow_prev {
        left: -40px;
      }
      &.slider_arrow_next {
        right: -40px;
      }
    }
  }
  @media (max-width: 999px) {
    .slider .item .item_wrapper .offer_text .top_text .title {
      font-size: 18px;
    }
    .slider .item .item_wrapper .offer_text .top_text .date_nights {
      font-size: 16px;
    }
    .slider .item .item_wrapper .offer_text .top_text .description {
      font-size: 14px;
    }
    .slider .slider_arrow {
      &.slider_arrow_prev {
        left: -30px;
      }
      &.slider_arrow_next {
        right: -30px;
      }
    }
  }
  @media (min-width: 768px) {
    .slider {
      &.slick-slider {
        .slick-list {
          overflow: visible;
        }

        .slick-slide {
          .item {
            opacity: 0.3;
            transition: opacity $ts_middle;
            position: relative;

            .item_wrapper .offer_image {
              .offer_image_item img {
                width: 200%;
                transition: width $ts_fast;
              }
              .price_box,
              .offer_number {
                opacity: 0;
              }
            }
            .item_wrapper .offer_text {
              opacity: 0;
              transition: opacity $ts_fast;
            }
            &:before,
            &:after {
              content: '';
              display: block;
              background-color: $clr_white;
              position: absolute;
              left: 0;
              width: 100%;
              z-index: 2;
              height: 50px;
              transition: all $ts_middle;
            }
            &:before {
              top: 0;
            }
            &:after {
              bottom: 0;
            }
          }

          &.add_animation {
            .item {
              opacity: 1;

              .item_wrapper .offer_image {
                .offer_image_item img {
                  width: 100%;
                }
                .price_box,
                .offer_number {
                  opacity: 1;
                  transition: opacity $ts_middle;
                  transition-delay: $ts_middle;
                }
              }
              .item_wrapper .offer_text {
                opacity: 1;
              }

              &:before,
              &:after {
                height: 0;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .center_wrapper {
      padding: 0;
    }

    .slider {
      .item .item_wrapper {
        display: block;

        .offer_image {
          width: 100%;

          .offer_image_item {
            height: 0;
            padding-bottom: 67%;
            min-height: 0;
          }
        }
        .offer_text {
          width: 100%;
          padding: 20px;

          .top_text .top_offer_text {
            display: none;
          }
        }
      }
      &.slick-slider .slick-list {
        overflow: visible;
      }
    }
    .slider .item .item_wrapper .offer_text .button_row {
      justify-content: center;
    }
    .slider .item .item_wrapper .offer_image .offer_number {
      font-size: 60px;
      top: -30px;
    }
    .slider .slider_arrow {

      &.slider_arrow_prev,
      &.slider_arrow_next {
        top: 0;
        transform: none;
        padding: 30% 0 0 0;
        margin: 0;

        &:before {
          background-color: rgba($clr_white, 0.8);
          padding: 15px 10px;
        }
      }
      &.slider_arrow_prev {
        left: -0px;
      }
      &.slider_arrow_next {
        right: -0px;
      }
    }
  }
}

/*  ==========================================================================
    ROOMS
    ========================================================================== */

// ROOMS LIST-VIEW
.room_list-view-EH {
  .room_leistungen {
    display: flex;
    justify-content: space-between;
    color: $clr_brown;
    text-align: center;
    margin-bottom: 45px;

    .leistung_item {
      padding: 10px;

      .icon {
        font-size: 55px;
        display: block;
        padding-bottom: 10px;

        &.icon_leistung-icons_leistungen-06:before {
          font-size: 0.8em;
        }
      }
      .leistung_name {
        text-transform: uppercase;
        font-weight: $fw_medium;
        font-size: 21px;
      }
    }
  }
  .room_filter {
    display: flex;

    .filter_item {
      @include normalButton($clr_white, $clr_gray_button, $clr_gray_button, true, false);
      margin-right: 15px;
      font-size: 14px;
      min-width: 0;
      width: 100%;
      cursor: pointer;

      &.active {
        color: $clr_white;
        background-color: $clr_gray_button;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .list {
    .item {
      .room_background {
        background-color: $clr_cm_offerlistIcon_roomList;
      }
      .room_image {
        .image_preview_slider {
          position: relative;

          .slider_arrow {
            position: absolute;
            bottom: 30px;
            font-size: 35px!important;
            z-index: 1;

            &.slider_arrow_prev {
              @include sliderArrowNormal(true, $clr_white, $clr_gold);
              right: 90px;
            }
            &.slider_arrow_next {
              @include sliderArrowNormal(false, $clr_white, $clr_gold);
              right: 50px;
            }
          }

          &:after {
            @include font_icomoon();
            content: $icon_zoom;
            position: absolute;
            bottom: 30px;
            left: 50px;
            color: $clr_white;
            font-size: 40px;
            pointer-events: none;
          }
        }
      }
      .room_text {
        .top_text {
          color: $clr_brown;

          .room_type {
            font-size: 16px;
            margin-bottom: 5px;
          }
          .title {
            font-size: 30px;
            margin-bottom: 15px;
            font-weight: $fw_light;
          }
          .infos {
            margin-bottom: 15px;

            > div {
              display: flex;
              align-items: center;

              &:first-child {
                margin-bottom: 5px;

                .icon {
                  font-size: 1.2em;
                }
              }

              .icon {
                width: 35px;
                display: block;
              }
            }
          }
          .description {
            color: $clr_gray_fliestext;
          }
        }
      }

      &.not_filtered {
        display: none!important;
      }
    }
  }
  .details {
    .detail_popup_item {
      .detail_top {
        .detail_images {
          position: relative;

          .image_detail_slider {

            .slider_arrow {
              position: absolute;
              bottom: 30px;
              font-size: 35px!important;
              z-index: 1;

              &.slider_arrow_prev {
                @include sliderArrowNormal(true, $clr_white, $clr_gold);
                right: 90px;
              }
              &.slider_arrow_next {
                @include sliderArrowNormal(false, $clr_white, $clr_gold);
                right: 50px;
              }
            }
          }
          .image_text_content {
            .room_type {
              font-size: 16px;
              display: flex;
              flex-direction: column;

              &:before {
                @include font_icomoon();
                content: $icon_zoom;
                margin-bottom: 5px;
              }
            }
            .title {
              font-size: 40px;
              font-weight: $fw_light;
            }
          }
          .price_wrapper {
            position: absolute;
            top: -50px;
            pointer-events: none;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            display: flex;
          }
        }
        .room_plan {
          display: flex;
          justify-content: flex-end;
          font-size: 16px;
          color: $clr_brown;
          padding: 15px 0;

          a {
            color: inherit;
            cursor: pointer;

            &:hover {
              color: $clr_gold;
            }
          }
          .icon {
            margin-right: 10px;
          }
        }
      }
      .detail_bottom {
        .detail_text {
          .infos {
            margin-bottom: 15px;
            color: $clr_brown;

            > div {
              display: flex;
              align-items: center;

              &:first-child {
                margin-bottom: 5px;

                .icon {
                  font-size: 1.2em;
                }
              }

              .icon {
                width: 35px;
                display: block;
              }
            }
          }
        }
        .price_list {
          background-color: $clr_gray_button;
          margin-top: 25px;

          .season_price_switcher {
            display: flex;

            .switcher_item {
              background-color: $clr_white;
              color: $clr_gray_button;
              width: 50%;
              height: 100%;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 60px;
              padding: 20px;
              text-transform: uppercase;
              box-shadow: inset 0 0 0 2px $clr_gray_button;
              cursor: pointer;
              font-weight: $fw_regular;

              .icon {
                font-size: 22px;
                margin-right: 10px;
              }

              &.active {
                background-color: $clr_gray_button;
                color: $clr_white;
              }
            }
          }
          .price_table {
            color: $clr_brown;
            padding: 30px;

            table {
              margin: 0;
              table-layout: fixed;
              min-width: 500px;

              tr {
                background-color: transparent;
                border-bottom: 1px solid $clr_gray_button;


                th {
                  background-color: transparent;
                  font-weight: $fw_medium;
                }

                > th,
                > td {
                  &:first-child {
                    text-align: left;
                    color: $clr_white;
                  }
                }

                > td {
                  font-weight: $fw_light;

                  &.season_date span,
                  &.season_price {
                    white-space: nowrap;
                  }
                  &:nth-child(2) {
                    background-color: $clr_cm_offerlistIcon_roomList;
                  }
                  &:nth-child(3) {
                    background-color: $clr_white;
                  }
                }

                &.season_row {
                  display: none;

                  &.active {
                    display: table-row;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 999px) {
    .room_leistungen {
      margin-bottom: 30px;
      flex-wrap: wrap;
      justify-content: center;

      .leistung_item {
        width: calc(100% / 3);

        .icon {
          font-size: 35px;
        }
        .leistung_name {
          font-size: 16px;
        }
      }
    }
    .list {
      .item .room_image .image_preview_slider:after {
        bottom: 15px;
        left: 15px;
        font-size: 25px;
      }
      .item .room_image .image_preview_slider .slider_arrow {
        bottom: 15px;
        font-size: 30px!important;

        &.slider_arrow_prev {
          right: 50px;
        }
        &.slider_arrow_next {
          right: 15px;
        }
      }
      .item .room_text .top_text .room_type {
        font-size: 14px;
      }
      .item .room_text .top_text .title {
        font-size: 18px;
        margin-bottom: 10px;
      }
      .item .room_text .top_text .infos {
        margin-bottom: 10px;
      }
    }
    .details {
      .detail_popup_item {
        .detail_top .detail_images .image_detail_slider .slider_arrow {
          &.slider_arrow_prev,
          &.slider_arrow_next {
            top: 20px;
            bottom: auto;
            font-size: 30px!important;
          }
          &.slider_arrow_prev {
            right: 50px;
          }
          &.slider_arrow_next {
            right: 20px;
          }
        }
        .detail_top .detail_images .image_text_content .room_type {
          font-size: 14px;
        }
        .detail_top .detail_images .image_text_content .title {
          font-size: 25px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .room_filter {
      flex-wrap: wrap;
      justify-content: center;

      .filter_item {
        width: auto;
        margin: 5px!important;
      }
    }
    .details {
      .detail_popup_item {
        .detail_top .detail_images .image_detail_slider .slider_arrow {
          &.slider_arrow_prev,
          &.slider_arrow_next {
            bottom: 20px;
            top: auto;
          }
        }
        .detail_top .detail_images > * {
          order: 1;
        }
        .detail_top .detail_images .price_wrapper {
          position: relative;
          transform: none;
          order: 2;
          margin-bottom: -55px;
          z-index: 5;
          top: auto;
          left: auto;
        }
        .detail_top .detail_images .price_wrapper .price_box {
          margin: auto;
        }
        .detail_top .detail_images .image_text_content {
          order: 3;
        }
        .detail_top .detail_images .image_text_content .room_type:before {
          display: none;
        }
        .detail_top .detail_images .image_text_content .title {
          font-size: 20px;
          font-weight: $fw_medium;
        }
        .detail_top .room_plan {
          font-size: 14px;
          padding: 10px 0;
        }
        .detail_bottom .price_list .season_price_switcher .switcher_item {
          flex-direction: column;
          padding: 10px;
          min-height: 0;

          .icon {
            margin: 0 0 10px 0;
          }
        }
        .detail_bottom .price_list {
          margin-top: 25px;

          .price_table {
            padding: 10px;

            table {
              table-layout: inherit;
              min-width: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 499px) {
    .room_leistungen {
      .leistung_item {
        width: 50%;

        .leistung_name {
          font-size: 14px;
        }
      }
    }
  }
}
// ROOMS PRICE-OVERVIEW
.room_price-overview-EH {

  .season_price_switcher {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    display: none!important;

    .switcher_item {
      background-color: $clr_white;
      color: $clr_gray_button;
      width: 50%;
      max-width: 335px;
      height: 100%;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
      padding: 20px;
      text-transform: uppercase;
      box-shadow: inset 0 0 0 2px $clr_gray_button;
      cursor: pointer;
      font-weight: $fw_regular;
      margin-right: 15px;

      .icon {
        font-size: 22px;
        margin-right: 10px;
      }

      &.active {
        background-color: $clr_gray_button;
        color: $clr_white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .desktop_list {
    .item_wrapper {
      display: flex;
      color: $clr_brown;
      font-size: 18px;

      .main_col {
        .outer_col {
          .inner_col {
            padding: 10px;
          }
        }

        &.col_left {
          width: 500px;
          display: flex;

          .outer_col {

            &:nth-child(1) {
              width: 65%;
            }
            &:nth-child(2) {
              width: 35%;
            }
          }
        }
        &.col_center {
          width: calc(100% - 580px);
          @include clearfix();

          .outer_col {
            float: left;

            .inner_col {
              min-width: 190px;
              text-align: center;
            }
          }
        }
        &.col_right {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          position: relative;

          &:before {
            content: '';
            display: block;
            position: absolute;
            right: 100%;
            top: 0;
            width: 30px;
            height: 100%;
            background: linear-gradient(to left, $clr_cm_offerlist, transparent);
          }
        }
      }

      &.date_wrapper {
        position: sticky;
        top: 0;
        background-color: $clr_white;
        z-index: 5;

        .col_left .col_1_2,
        .col_center .inner_col {
          position: relative;

          &:after {
            content: '';
            display: block;
            width: 1px;
            height: 80%;
            position: absolute;
            right: 0;
            top: 10%;
            background-color: $clr_brown;
          }
        }

        .col_left {
          .col_1_1 {
            display: flex;
            align-items: center;

            .room_filter {
              position: relative;
              text-transform: uppercase;
              font-weight: $fw_medium;
              cursor: pointer;

              .room_filter_content {
                position: relative;
                padding: 0 35px 0 10px;

                &:after {
                  @include font_icomoon();
                  content: $icon_drop_down_pfeil_unten;
                  display: block;
                  position: absolute;
                  right: 5px;
                  font-size: 7px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
              .room_filter_list {
                display: none;
                position: absolute;
                left: 0;
                top: 100%;
                padding: 20px;
                background-color: $clr_white;

                .filter_item {
                  padding: 5px 0;

                  &:hover {
                    color: $clr_gold;
                  }
                  &.active {
                    display: none;
                  }
                }
              }

              &:hover {
                .room_filter_list {
                  display: block;
                }
              }
            }
          }
          .col_1_2 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .col_center {
          .inner_col {
            > div:first-child {
              text-transform: capitalize;
              margin-bottom: 5px;
            }
          }
        }
        .col_right {
          &:before {
            background: linear-gradient(to left, $clr_white, transparent);
          }
        }
        .icon {
          cursor: pointer;
        }
      }
      &.rooms_wrapper {
        background-color: $clr_cm_offerlist;
        margin-bottom: 2px;

        .col_left {
          .col_1_1 {
            display: flex;
            align-items: center;

            .room_name {
              color: inherit;
              display: block;
              padding: 0 10px;

              &:hover {
                color: $clr_gold;
              }
            }
          }
        }

        .column_board_code1,
        .column_board_code2 {
          position: relative;

          &:after {
            content: '';
            display: block;
            width: 1px;
            height: 80%;
            position: absolute;
            right: 0;
            background-color: $clr_brown;
          }
        }
        .column_board_code1 {
          background-color: $clr_cm_offerlistIcon_roomList;

          &:after {
            bottom: 0;
          }
        }
        .column_board_code2 {
          &:after {
            top: 0;
          }
        }

        &.not_filtered {
          display: none;
        }
      }
    }
  }
  .mobile_list {
    display: none;
  }

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 999px) {
    .season_price_switcher {
      display: none;
    }
    .desktop_list {
      display: none;
    }
    .mobile_list {
      display: block;
      color: $clr_brown;

      .room_item {
        margin-bottom: 10px;
        background-color: $clr_cm_offerlist;

        .top {
          padding: 10px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .room_name {
            color: inherit;
            font-size: 18px;

            span {
              white-space: nowrap;
            }
          }
          .responsive_room_opener {
            padding: 20px;
            cursor: pointer;

            .icon {
              transform: rotate(90deg);
              font-size: 20px;
              display: block;
            }
          }
        }
        .bottom {
          display: none;

          .price_table {
            color: $clr_brown;
            padding: 10px;

            table {
              margin: 0;
              table-layout: fixed;
              min-width: 500px;

              tr {
                background-color: transparent;
                border-bottom: 1px solid $clr_cm_offerlist;

                th {
                  background-color: transparent;
                  font-weight: $fw_medium;
                }

                > th,
                > td {
                  color: inherit;

                  &:first-child {
                    text-align: left;
                  }
                }

                > td {
                  font-weight: $fw_light;

                  &.season_date span,
                  &.season_price {
                    white-space: nowrap;
                  }
                  &:nth-child(2) {
                    background-color: $clr_cm_offerlistIcon_roomList;
                  }
                  &:nth-child(3) {
                    background-color: $clr_white;
                  }
                }

                &.season_row {
                  display: none;

                  &.active {
                    display: table-row;
                  }
                }
              }
            }
          }
        }

        &.open {
          .top {
            .responsive_room_opener .icon {
              transform: rotate(-90deg);
            }
          }
          .bottom {
            display: block;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .mobile_list {
      .room_item {
        .top .room_name {
          font-size: 16px;
        }
        .bottom .price_table table {
          min-width: 0;
          table-layout: inherit;
        }
      }
    }
  }
}

/*  ==========================================================================
    ADDITIONALS
    ========================================================================== */

// ADDITIONAL LIST-VIEW
.additional_list-view-EH {
  .accordeon_item {

    .infos {
      color: $clr_brown;

      > div {
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 1.3em;
        width: 35px;
        display: flex;
        justify-content: center;
      }
    }
    .title {
      text-transform: none;
      font-weight: $fw_medium;
    }

    .accordeon_trigger {
      .headlines {
        display: flex;

        .title {
          width: 525px;
          padding-right: 15px;
        }
        .infos_top {
          display: flex;

          .duration {
            min-width: 200px;

            .icon {
              justify-content: flex-start;
            }
          }
        }
      }
    }
    .accordeon_text {
      .accordeon_text_wrapper {
        display: flex;

        .additional_image {
          width: 525px;
          padding-right: 50px;
        }
        .description {
          width: calc(100% - 785px);
          padding-right: 20px;

          &.no_image {
            width: calc(100% - 260px);
          }
        }
        .info_request {
          width: 260px;
          padding-left: 20px;
          border-left: 1px solid $clr_gray_button;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .infos_bottom {
            > div {
              margin-bottom: 10px;
            }
          }
          .button_row {
            margin-top: 45px;
            display: flex;

            .button {
              @include normalButton($clr_gray_button, $clr_white, $clr_gold, false, false);
              min-width: 0;
              width: 100%;
            }
          }
        }
      }
    }

    &.open {
      .accordeon_trigger {
        justify-content: flex-end;

        .headlines {
          display: none;
        }
      }
    }
  }

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 1399px) {
    .accordeon_item.open .accordeon_trigger {
      justify-content: space-between;
    }
    .accordeon_item .accordeon_trigger .headlines,
    .accordeon_item.open .accordeon_trigger .headlines {
      display: block;
    }
    .accordeon_item .accordeon_trigger .headlines .title {
      width: 100%;
    }
    .accordeon_item .accordeon_trigger .headlines .infos_top {
      margin-top: 5px;

      .duration {
        min-width: 0;
        padding-right: 10px;
      }
    }

    .accordeon_item .accordeon_text .accordeon_text_wrapper {
      flex-wrap: wrap;
    }
    .accordeon_item .accordeon_text .accordeon_text_wrapper .additional_image {
      width: 300px;
      padding-right: 25px;
    }
    .accordeon_item .accordeon_text .accordeon_text_wrapper .description {
      width: calc(100% - 300px);

      &.no_image {
        width: 100%;
      }
      .headlines {
        display: none;
      }
      > *:nth-child(2) {
        margin-top: 0;
      }
    }
    .accordeon_item .accordeon_text .accordeon_text_wrapper .info_request {
      border-left: none;
      padding: 0;
      width: 100%;

      .infos_bottom {
        display: none;

        .icon {
          width: 30px;
        }
      }
      .button_row {
        margin-top: 25px;
        display: flex;
        justify-content: center;

        .button {
          width: auto;
          min-width: 260px;
        }
      }
    }
  }
  @media (max-width: 699px) {
    .accordeon_item .accordeon_text .accordeon_text_wrapper {
      display: block;

      > div {
        width: 100%!important;
        padding: 0!important;
      }
      .additional_image {
        width: auto;
        max-width: 100%;
        margin: auto;
        display: block;
        padding-bottom: 20px;
      }
    }
  }
}