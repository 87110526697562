/*  ==========================================================================
    LINK BOXES
    ========================================================================== */
.link_boxes-CE {
  position: relative;
  padding: 100px 0;
  background-color: $clr_brown;

  .bg_wrapper {
    opacity: 0.5;

    .bg_item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 0;
      transition: all $ts_middle;

      &.active {
        opacity: 1;
      }
    }
  }
  .box_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .item {
      color: $clr_white;
      width: 340px;
      height: 340px;
      box-shadow: inset 0 0 0 2px $clr_white;
      margin: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      transition: all $ts_fast;
      text-align: center;

      .icon {
        @include sized_menu_icons();
        font-size: 55px;
        padding: 60px 0 20px 0;
      }
      .title {
        text-transform: uppercase;
        font-size: 21px;
        font-weight: $fw_medium;
        width: 100%;
      }

      &:hover,
      &.active {
        background-color: rgba($clr_white, 0.9);
        color: $clr_brown;
      }
    }
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 999px) {
  .link_boxes-CE {
    padding: 0;
    background-color: transparent;

    .bg_wrapper {
      display: none;
    }
    .box_wrapper {
      .item {
        color: $clr_brown;
        width: auto;
        height: auto;
        box-shadow: none;

        .icon {
          font-size: 35px;
          padding-top: 0;
        }
        .title {
          font-size: 14px;
        }

        &:hover {
          color: $clr_gold;
        }
      }
    }
  }
}
@media (min-width: 600px) {
  .link_boxes-CE .box_wrapper .item {
    background-image: none!important;
  }
}
@media (max-width: 599px) {
  .link_boxes-CE .box_wrapper {
    display: block;
  }
  .link_boxes-CE .box_wrapper .item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 5px 0;
    min-height: 145px;
    color: $clr_white!important;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($clr_brown, 0.5);
    }
    .icon,
    .title {
      position: relative;
    }

    &:hover:before {
      background-color: rgba($clr_brown, 0.9);
    }
  }
}