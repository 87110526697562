/*  ==========================================================================
    IMAGE GALLERY
    ========================================================================== */

.image_gallery-CE {
  .wrapper {
    max-width: 1800px;

    .slider {
      @include clearfix();

      .item {
        float: left;
        width: 33%;
      }

      &.slick-slider {
        .slider_arrow {

          &.slider_arrow_prev {
            @include sliderArrowAbsolute(true, $clr_black, $clr_gold, 30px);
            z-index: 2;
          }
          &.slider_arrow_next {
            @include sliderArrowAbsolute(false, $clr_black, $clr_gold, 30px);
            z-index: 2;
          }
        }

        .item {
          position: relative;
          opacity: 0.5;
          transition: opacity $ts_slow;

          &:before,
          &:after {
            content: '';
            display: block;
            background-color: $clr_white;
            position: absolute;
            left: 0;
            width: 100%;
            z-index: 1;
            height: 0;
            transition: all $ts_slow;
          }
          &:before {
            top: 0;
          }
          &:after {
            bottom: 0;
          }
        }
        .slick-slide:not(.add_animation) {
          .item {
            opacity: 0.5;

            &:before,
            &:after {
              height: 50px;
            }
          }
        }
        .slick-center {
          .item {
            opacity: 1;
          }
        }

        &:before,
        &:after {
          content: '';
          display: block;
          background-color: $clr_white;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100px;
          z-index: 1;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
      }
    }
    .bottom_more_link {
        @include more_icon_link($clr_gray_fliestext, $clr_gray_button, $icon_imageGallery);
    }
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 999px) {
  .image_gallery-CE .wrapper .slider .item {
    width: 50%;
    padding: 5px;
  }
  .image_gallery-CE .wrapper .slider.slick-slider {
    &:before,
    &:after {
      width: 55px;
    }
  }
  .image_gallery-CE .wrapper .slider.slick-slider .slider_arrow.slider_arrow_prev {
    left: 20px;
  }
  .image_gallery-CE .wrapper .slider.slick-slider .slider_arrow.slider_arrow_next {
    right: 20px;
  }
}
@media (max-width: 599px) {
  .image_gallery-CE .wrapper .slider .item {
    width: 100%;
    padding: 5px 0;
  }
  .image_gallery-CE .wrapper .slider.slick-slider {
    &:before,
    &:after {
      display: none;
    }
  }
  .image_gallery-CE .wrapper .slider.slick-slider .slider_arrow {
    background-color: rgba($clr_white, 0.8);

    &.slider_arrow_prev {
      left: 0;
      margin-left: 0;
      padding: 15px 10px;
    }
    &.slider_arrow_next {
      right: 0;
      margin-right: 0;
      padding: 15px 10px;
    }
  }
}
