/*  ==========================================================================
    ARROWS
    ========================================================================== */


// Slider-Arrow
@mixin sliderArrowNormal($left_direction, $color, $color_hover) {
  @include font_icomoon();
  cursor: pointer;
  color: $color;
  font-size: 75px;
  padding: 1%;

  &:hover {
    color: $color_hover;
  }

  @if $left_direction {
    &:before {
      content: $icon_slider_pfeil_links;
    }
  } @else {
    &:before {
      content: $icon_slider_pfeil_rechts;
    }
  }

  @media (max-width: 999px) {
    font-size: 42px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
  }
}
@mixin sliderArrowAbsolute($left_direction, $color, $color_hover, $distance) {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);

  @include sliderArrowNormal($left_direction, $color, $color_hover);

  @if $left_direction {
    left: $distance;
    margin-left: -1%;
  } @else {
    right: $distance;
    margin-right: -1%;
  }
}