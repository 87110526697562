/*  ==========================================================================
    BUTTONS
    ========================================================================== */

@mixin cookiebanner($color_bg ,$color, $buttoncolor_bg, $buttoncolor, $button_hover_color_bg, $button_hover_color) {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $color_bg;
  color: $color;
  width: 100%;
  z-index: 10000;
  font-size: 14px;

  /* Support `hidden` attribute in IE < 11 */
  &[hidden] {
    display: none;
  }

  .cb-infotext {
    max-width: 1920px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }

  .cb-text {
    padding-right: 50px;
  }

  .cb-infolink {
    text-decoration: underline;
    color: inherit;
  }

  .cb-button {
    display: flex;
    align-items: center;
    background-color: $buttoncolor_bg;
    color: $buttoncolor;
    padding: 10px 20px;
    font-size: 20px;
    min-width: 230px;
    justify-content: center;

    &:hover {
      background-color: $button_hover_color_bg;
      color: $button_hover_color;
    }
  }

  @media (max-width: 767px) {
    font-size: 10px;
    width: 100%;
    max-width: 300px;
    bottom: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .cb-infotext {
      display: block;
      padding: 10px;

      .cb-text {
        padding: 0;
        margin-bottom: 20px;
      }
      .cb-button {
        padding: 5px;
        font-size: 12px;
      }
    }
  }

}