/*  ==========================================================================
    SLOGAN
    ========================================================================== */

.slogan-CE {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  color: $clr_white;
  position: relative;
  padding: 100px 0;

  .wrapper {
    position: relative;
    a {
      color: inherit;
      &:hover {
        color: $clr_gold;
      }
    }
    .icon {
      font-size: 30px;
      display: block;
      margin-bottom: 15px;
    }
    .headlines {
      * {
        color: inherit;
        font-weight: 500;
      }
    }

  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($clr_brown, 0.7);
  }

  &.image_not_setted {
    color: $clr_brown;

    &:before {
      display: none;
    }
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 999px) {
  .slogan-CE {
    padding: 40px 0;
  }
  .slogan-CE .wrapper .icon {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .slogan-CE .wrapper .icon {
    font-size: 13px;
    margin-bottom: 10px;
  }
}