.dna-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  &.js-active {
    display: flex;
  }
  .dna-popup__background {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
  }
  .dna-popup__content {
    background-color: white;
    position: relative;
    z-index: 2;
    width: calc(100% - 20px);
    max-width: 800px;
    padding: 20px;
    .dna-popup__title {
      font-size: 25px;
      font-weight: 300;
      line-height: 1.3;
      margin-bottom: 10px;
    }
    .dna-popup__text {
      font-size: 16px;
    }
    .dna-popup__links {
      display: flex;
      margin-top: 15px;
      justify-content: center;
      .dna-popup__link {
        font-size: 16px;
        padding: 8px 14px;
        background-color: #baa467;
        color: white;
        margin-right: 5px;
        &::first-letter {
          text-transform: uppercase;
        }
        &:hover {
          background-color: #b39b58;
          color: white;
        }
      }
    }
  }
}
