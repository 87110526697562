/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.footer {
  position: relative;
  padding: 1px 0 100px 0;
  background-color: $clr_white;
  max-width: 1920px;
  margin: auto;

  .row_1 {
    .footer_info {
      color: $clr_white;
      font-size: 16px;
      text-align: center;
      font-weight: $fw_light;
      position: relative;
      background-size: cover;
      background-position: bottom center;
      background-repeat: no-repeat;
      transition: background-position 2s;

      .footer_info_wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;

        .sub_item {
          padding: 35px 0;
          width: 100%;

          .sub_wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            border-right: $footer_info_border;
            padding: 0 20px;
          }
          a {
            color: $clr_white;

            &:hover {
              color: $clr_brown;
            }
          }

          .title {
            font-size: 16px;
            text-transform: uppercase;
            padding-bottom: 25px;
            font-weight: $fw_medium;
          }
          .content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          &:last-child {
            .sub_wrapper {
              border-right: none;
            }
          }
        }

        .hotel_info {
          .content {
            .tel_mail {
              font-size: 23px;
              font-weight: $fw_medium;
              padding-bottom: 20px;

              a {
                display: block;
              }
            }
            .contact_info {
              span {
                display: block;
              }
            }
          }
        }
        .download {
          color: $clr_white;
          display: block;

          .content {
            .download_icon {
              font-size: 90px;
            }
          }

          &:hover {
            background-color: rgba($clr_brown, 0.5);
          }
        }
        .weather {
          .content {
            .weather_container {
              .icon {
                font-size: 55px;
              }
              .date {
                font-weight: $fw_medium;
              }
              .temperatures {
                font-size: 36px;
              }
            }
          }
        }
        .social_reviews {
          .content {
            justify-content: space-around;

            .social_items {
              font-size: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;

              .item {
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: $footer_info_border;
                padding: 0 20px;

                &:last-child {
                  border-right: none;
                }
              }
            }
            .review_items {
              font-size: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              gap: 10px 20px;
              margin-top: 20px;
            }
          }
        }
      }

      &.init_effect {
        background-position: center center;
      }
      &:before {
        content: '';
        background-color: $clr_brown;
        opacity: 0.75;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .row_2_3_wrapper {
    display: flex;
    max-width: 1920px;
    margin: auto;

    > * {
      width: 50%;
    }
  }
  .row_2 {
    display: flex;
    align-items: center;
    position: relative;

    .title_wrapper {
      display: none;
    }
    .wrapper {

      .button_row {
        position: absolute;
        bottom: 40px;
        right: 50px;
        display: flex;

        .calc_route {
          @include normalButton($clr_gray_button, $clr_white, $clr_brown, false, false);
        }
      }

      &:hover .gmaps_container {
        opacity: 1;
      }
    }
  }
  .row_3 {
    color: $clr_gray_dark;
    background-color: rgba($clr_cm_offerlistIcon_roomList, 0.55);
    padding: 40px 0;

    > * {
      display: flex;
    }

    .title_wrapper {
      padding-right: 10%;

      .title {
        font-size: 16px;
        text-transform: uppercase;
        padding-bottom: 25px;
        font-weight: $fw_medium;
      }
    }
    .wrapper {
      width: 100%;
      max-width: 450px;
    }

    #std_newsletter_2017 {
      .bn-form__submit button {
        box-shadow: inset 0 0 0 2px $clr_brown;
        background-color: transparent!important;
        border-color: transparent!important;

        &:hover {
          color: $clr_white!important;
          background-color: $clr_brown!important;
        }
      }
    }
  }
  .row_4 {
    margin: 30px 0;

    .wrapper {
      display: flex;
      justify-content: space-between;

      .left {
        font-size: 14px;

        > div {
          display: inline;
        }
        span {
          text-transform: uppercase;
        }
        a {
          color: $clr_gray_fliestext;

          &:after {
            color: $clr_gray_fliestext;
            display: inline;
            content: ' | ';
          }
          &:hover {
            color: $clr_gold;
          }
        }
      }
      .right {
        display: none;

        a {
          font-size: 16px;
          color: $clr_black;
        }
      }
    }
  }
  .row_5 {
    .wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      .partner_item {
        display: block;
        padding: 25px;
        filter: grayscale(1);
        transition: all $ts_fast;

        img {
          width: auto;
        }

        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
  .row_6 {
    z-index: 998;
    background-color: $clr_brown;
    height: 85px;
    position: fixed;
    left: 0;
    bottom: -85px;
    transition: bottom $ts_slow;
    width: 100%;

    .wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      max-width: 1850px;

      .bn_dynForm {
        width: 75%;
        margin-right: 25px;

        .bn-form__submit button {
          padding: 10px!important;
        }
      }
    }

    &.docked {
      bottom: 0;
    }
  }
}

// hide quickrequest
body.quickrequest_disabled .footer .row_6 {
  display: none!important;
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 1399px) {
  .footer .row_1 .footer_info .footer_info_wrapper {
    flex-wrap: wrap;

    .sub_item {
      width: calc(100% / 2);

      &:nth-child(2) .sub_wrapper {
        border-right: none;
      }
    }
    .social_reviews {
      width: 100%;
      border-top: $footer_info_border;

      .title {
        display: none;
      }
      .content {
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .social_items {
          .item:last-child {
            border-right: $footer_info_border;
          }
        }
        .review_items {
          margin-top: 0;
          gap: 0;
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70px;
            border-right: $footer_info_border;
            padding: 0 25px;

            &:last-child {
              border-right: none;
              padding: 0 25px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .footer .row_3 {
    > * {
      display: block;
    }
    .title_wrapper {
      padding: 0;
    }

  }
}
@media (max-width: 1023px) {
  .footer .row_6 {
    display: none;
  }
}
@media (max-width: 999px) {
  .footer .row_1 .footer_info {
    font-size: 13px;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .sub_item {
    padding: 25px 0;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .sub_item .sub_wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .sub_item:last-child .sub_wrapper {
    padding: 0;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .sub_item .title {
    font-size: inherit;
    padding-bottom: 20px;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .hotel_info .content .tel_mail {
    font-size: inherit;
    padding-bottom: 15px;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .download .content .download_icon {
    font-size: 35px;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .weather .content .weather_container .temperatures {
    font-size: inherit;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .weather .content .weather_container .temperatures > div {
    padding-top: 5px;
  }
  .footer .row_1 .footer_info .footer_info_wrapper .social_reviews .content .social_items,
  .footer .row_1 .footer_info .footer_info_wrapper .social_reviews .content .review_items {
    font-size: 18px;
    flex-wrap: nowrap;
  }

  .footer .row_3 .title_wrapper .title {
    font-size: 14px;
    padding-bottom: 20px;
  }

  .footer .row_4 {
    margin: 10px;
  }
  .footer .row_4 .wrapper {
    display: block;
    text-align: center;
  }
  .footer .row_4 .wrapper .left {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .footer .row_4 .wrapper .right a {
    font-size: 14px;
  }
  .footer .row_5 .wrapper .partner_item {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .footer {
    padding-bottom: 60px;
  }
  .footer .row_2_3_wrapper {
    display: block;

    > * {
      width: 100%;
    }
  }
  .footer .row_3 > * {
    display: flex;
  }
  .footer .row_3 .title_wrapper {
    padding-right: 10%;
  }
  .footer .row_4 .wrapper .left > div {
    display: block;
  }
  .footer .row_4 .wrapper .left .legalmenu {
    margin-bottom: 5px;

    a:last-child:after {
      display: none;
    }
  }
}
@media (max-width: 599px) {
  .footer .row_1 .footer_info {
    background-image: none!important;
    font-size: 14px;

    .footer_info_wrapper {
      display: block;

      .sub_item {
        width: 100%;
        padding: 0;

        .sub_wrapper {
          border-right: 0;
          border-bottom: $footer_info_border;
          padding: 15px 0;
        }

        .title {
          padding-bottom: 0;
        }
        .content {
          display: none;
          padding-top: 10px;
        }

        &.mobile_accordeon {

          .mobile_accordeon_trigger {
            display: flex;
            flex-direction: column;

            &:after {
              @include font_icomoon();
              content: $icon_drop_down_pfeil_unten;
              font-size: 6px;
              padding-top: 5px;
            }
          }
          &.open {
            .mobile_accordeon_trigger:after {
              display: none;
            }
            .content {
              display: block;
            }
          }
        }
      }
      
      .hotel_info {
        .content {
          display: block;

          .tel_mail {
            padding-bottom: 10px;
          }
        }
      }
      .social_reviews {
        border-top: none;

        .sub_wrapper {
          padding: 15px 0!important;
        }
        .title {
          display: block;
        }
        .content {
          .social_items {
            padding-bottom: 20px;

            .item {
              &:last-child {
                border-right: none;
              }
            }
          }
          .review_items {
            gap: 10px 20px;
            flex-wrap: wrap;
            .item {
              border-right: none;
              padding: 0;
              &:last-child {
                padding: 0;
              }
            }
          }

          .social_items,
          .review_items {
            font-size: 25px;

            .item {
              height: 35px;
            }
          }
        }
      }
    }

    &:before {
      opacity: 0.5;
    }
  }
  .footer .row_2,
  .footer .row_3 {
    background-color: rgba($clr_brown, 0.5);
    padding: 15px 0;
    display: block;

    .title_wrapper {
      display: block;

      .mobile_accordeon_trigger {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        color: $clr_white;
        text-align: center;
        font-size: 14px;
        font-weight: $fw_medium;

        &:after {
          @include font_icomoon();
          content: $icon_drop_down_pfeil_unten;
          font-size: 6px;
          padding-top: 5px;
        }
      }
    }
    .wrapper {
      display: none;
      padding-top: 10px;
    }

    &.open {
      .title_wrapper .mobile_accordeon_trigger:after {
        display: none;
      }
      .wrapper {
        display: block;
      }
    }
  }

  .footer .row_2 {
    .wrapper {
      position: relative;

      .gmaps_container {
        opacity: 1;
        height: 300px;
      }
      .button_wrapper {
        background-color: $clr_white;
        padding-bottom: 15px;

        .button_row {
          position: static;
        }
      }
    }
  }
  .footer .row_3 {
    margin: 0;
    padding-top: 0;

    > * {
      display: block;
    }

    .wrapper {
      margin: auto;
    }

    .title_wrapper {
      padding-top: 15px;
      padding-right: 0;
      border-top: $footer_info_border;
    }
    #std_newsletter_2017 {
      .bn-form-newsletter {
        background-color: transparent!important;
      }
      .bn-form__submit button {
        box-shadow: none;
        background-color: $clr_white!important;

        &:hover {
          background-color: $clr_brown!important;
        }
      }
    }
  }
}