/*  ==========================================================================
    ACCORDEON BOXES

    Achtung: verwendung auch bei Additionals!!
    ========================================================================== */

.accordeon_boxes-CE {

  .accordeon_item {
    border-top: 1px solid $clr_gray_fliestext;
    border-bottom: 1px solid $clr_gray_fliestext;
    margin-bottom: 15px;
    padding: 10px 0;
    color: $clr_brown;

    .accordeon_trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .headlines > * {
        margin: 0;
        color: inherit;
      }

      &:after {
        @include font_icomoon();
        content: $icon_drop_down_pfeil_unten;
        display: block;
        font-size: 7px;
        padding: 0 15px;
        transition: transform $ts_fast;
      }
      &:hover {
        color: $clr_gold;
      }
    }
    .accordeon_text {
      display: none;
      margin: 1em 0;
    }

    &.open {
      .accordeon_trigger:after {
        transform: rotate(180deg);
      }
      .accordeon_text {
        display: block;
      }

      .accordeon_closer {
        cursor: pointer;

        &:after {
          @include font_icomoon();
          content: $icon_drop_down_pfeil_unten;
          display: block;
          font-size: 7px;
          padding: 1em 15px;
          transform: rotate(180deg);
        }
        &:hover {
          color: $clr_gold;
        }
      }
    }
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 999px) {

}