/*  ==========================================================================
    GOOGLE MAPS
    ========================================================================== */

.google_maps-CE {
  .route_calc {
    background-color: $clr_brown;
    padding: 30px 0;

    .input_row {
      display: flex;
      justify-content: center;
      padding-bottom: 15px;

      input {
        padding: 5px;
      }
    }
    .button_row {
      display: flex;
      justify-content: center;

      .button {
        @include normalButton($clr_gray_button, $clr_white, $clr_gold, false, false);
        cursor: pointer;
      }
    }
  }
  .content_gmaps_container {
    height: 560px;
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 999px) {
  .google_maps-CE .content_gmaps_container {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .google_maps-CE .content_gmaps_container {
    height: 350px;
  }
}