/*  ==========================================================================
    STANDARD CONTENT
    ========================================================================== */

@mixin std_content() {
  margin: 70px 0;

  .headlines {
    > * {
      font-weight: $fw_regular;
      margin-bottom: 20px;
      color: $clr_brown;
    }

    h1,
    .headline {
      font-size: 42px;
      font-weight: $fw_light;
      line-height: 1.3;
    }
    h2,
    .sub_headline{
      font-size: 20px;
      text-transform: uppercase;
      font-weight: $fw-medium;
      line-height: 1.5;
    }
    h3,
    .subsub_headline {
      font-size: 18px;
      text-transform: uppercase;
      color: $clr_gold;
    }
  }
  .std_text {
    font-size: 16px;
    line-height: 1.7;
    font-weight: $fw_light;

    h4 {
      font-size: inherit;
      text-transform: uppercase;
      color: $clr_gray_dark;
      line-height: 1.4;
    }
    a {
      color: $clr_gold;

      &:hover {
        text-decoration: underline;
      }
    }
    ol,
    ul,
    table,
    p {
      margin: 1em 0;
    }
    ol,
    ul {
      padding-left: 1em;
    }
    b,
    strong {
      font-weight: $fw_medium;
    }

    .responsive-table,
    .bn-scrabble__table_wrap {
      width: 100%;
      overflow-x: auto;
      font-weight: $fw_regular;

      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;

        tr > * {
          padding: 10px;
          border: none;
        }
        tr {
          background-color: #eae9e7;

          &:nth-child(2n) {
            background-color: #dbdad7;
          }
        }
        th {
          background-color: $clr_brown;
          color: #fff;
          font-weight: $fw_semiBold;
        }
        p {
          margin: 0;
        }
      }
    }

    > * {
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &.no_space_bottom {
    margin-bottom: 0;

    + .std_content {
      margin-top: 0;
    }
  }

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 999px) {
    margin: 30px 0;

    .headlines {
      > * {
        margin-bottom: 10px;
      }
      h1,
      .headline {
        font-size: 26px;
      }
      h2,
      .sub_headline{
        font-size: 15px;
      }
      h3,
      .subsub_headline {
        font-size: 15px;
      }
    }
    .std_text {
      font-size: 15px;
    }
  }
  @media (max-width: 767px) {

    .headlines {
      h1,
      .headline {
        font-size: 19px;
      }
    }
    .std_text {
      line-height: 1.4;
    }
  }

}