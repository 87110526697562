/*  ==========================================================================
    ICONS
    ========================================================================== */

// font-icomoon
@mixin font_icomoon() {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin font_icomoon-leistungen() {
  font-family: 'icomoon_leistungen' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// sized icons (im Verhältnis) - static
@mixin sized_menu_icons() {
  &.icon-gourmet {
    &:before {
      font-size: 1em;
    }
  }
  &.icon-zimmer {
    &:before {
      font-size: 0.65em;
    }
  }
  &.icon-wellness {
    &:before {
      font-size: 0.72em;
    }
  }
  &.icon-mountain {
    &:before {
      font-size: 0.35em;
    }
  }
}
