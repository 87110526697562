/*  ==========================================================================
    BUTTONS
    ========================================================================== */

@mixin more_icon_link ($color,$color_hover, $icon) {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  font-size: 18px;

  a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $color;
    transition: color $ts_superfast;
    font-weight: $fw_medium;

    &:before {
      @include font_icomoon();
      display: block;
      content: $icon;
      font-size: 22px;
      padding-right: 10px;
    }
    &:after {
      @include font_icomoon();
      display: block;
      content: $icon_weiterPfeil;
      padding-left: 10px;
      font-size: 10px;
      transition: all $ts_superfast;
    }

    &:hover {
      color: $color_hover;

      &:after {
        transform: translateX(5px);
      }
    }
  }

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 999px) {
    font-size: 15px;
    padding-top: 20px;

    a:before {
      font-size: 18px;
    }
  }
  @media (max-width: 767px) {
    padding-top: 15px;
  }
}
@mixin normalButton($bg_color, $color, $bg_color_hover, $outline, $arrow) {
  text-transform: uppercase;
  font-size: 16px;
  min-width: 260px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color;
  background-color: $bg_color;
  transition: background-color $ts_superfast;
  padding: 15px 10px;
  text-align: center;
  position: relative;

  &:hover {
    background-color: $bg_color_hover;
  }

  @if $arrow {
    &:after {
      @include font_icomoon();
      content: $icon_slider_pfeil_rechts;
      display: flex;
      height: 100%;
      align-items: center;
      position: absolute;
      top: 0;
      right: 20px;
    }
  }

  @if $outline {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $color;

    &:hover {
      color: $bg_color;
      background-color: $color;
    }
  }

  /* RESPONSIVE BEHAVIOR
   * --------------------------------------------------------------------------- */
  @media (max-width: 999px) {
    font-size: 15px;
    min-height: 50px;
    min-width: 0;
    padding: 10px 30px;

    @if $arrow {
      &:after {
        right: 15px;
      }
    }
  }
  @media (max-width: 767px) {

  }
}