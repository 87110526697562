/*  ==========================================================================
    VIDEO ELEMENT
    ========================================================================== */
.video_element-CE {

  .video_container {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
    .video_overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($clr_brown, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        border-left: 60px solid $clr_gold;
        transition: transform $ts_fast;
      }

      &:hover:after {
        transform: scale(1.3);
      }
    }

    &.clicked .video_overlay {
      display: none;
    }
  }
}