.text_plugin-CE {
  .bn-scrabble {
    font-size: inherit;
    max-width: none;
    padding: 0;

    * {
      font-size: inherit!important;
      line-height: inherit!important;
    }
  }
  img {
    width: auto;
  }
}