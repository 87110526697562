/*  ==========================================================================
    FALLBACK PAGE
    Styling for the fallback page (older browser, eg. IE, Safari 5, ...)

    INFO:
    - if you want to customize something, do it with care (eg. only colors)
    - contributions for the kickstarter are welcome!
    ========================================================================== */

.fallbackPage {
  background-color: #ffffff;
  color: #000000;
  min-height: 100vh;
  a {
    color: #000000;
    text-decoration: none;
    &:visited {
      color: #000000;
    }
    &:hover {
      color: rgb(31, 42, 48);
    }
  }
  > div {
    display: table;
    height: 100vh;
    width: 100%;
    > div {
      display: table-row;
      height: auto;
      width: 100%;
      > div {
        display: table-cell;
        padding: 0 40px;
        vertical-align: middle;
      }
    }
  }
  .top,
  .middle,
  .bottom {
    height: 25%;
    text-align: center;
    .inner {
      margin: 0 auto;
      max-width: 960px;
      width: 100%;
    }
  }
  .top {
    > div {
      padding-top: 80px;
      vertical-align: bottom;
      .fb_logo {
        display: inline-block;
        img {
          height: auto;
          width: auto;
          max-height: 200px;
          max-width: 210px;
        }
      }
    }
  }
  .middle {
    height: 50%;
    text-align: center;
    > div {
      padding-bottom: 40px;
      padding-top: 40px;
      vertical-align: middle;
      .fb_contact {
        font-size: 20px;
        a {
          display: inline-block;
          span {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .bottom {
    > div {
      padding-bottom: 80px;
      vertical-align: top;
      .fb_update {
        .text {
          font-size: 18px;
          margin-bottom: 20px;
        }
        .browser {
          margin-top: 20px;
          > a {
            display: inline-block;
            font-size: 18px;
            img {
              height: auto;
              width: 100%;
              max-width: 100px;
            }
            span {
              display: block;
              margin-top: 5px;
            }
            + a {
              margin-left: 40px;
            }
          }
        }
      }
    }
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media only screen and (max-width: 768px) {
  .fallbackPage {
    > div {
      > div {
        > div {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
    .bottom {
      > div {
        .fb_update {
          .browser {
            > a {
              img {
                max-width: 75px;
              }
            }
          }
        }
      }
    }
  }
}

// phone
@media only screen and (max-width: 480px) {
  .fallbackPage {
    .bottom {
      > div {
        .fb_update {
          .browser {
            > a {
              img {
                max-width: 50px;
              }
            }
          }
        }
      }
    }
  }
}