/*  ==========================================================================
    MODULES
    Collection of all modules (except those which should be integrated 'inline')
    ========================================================================== */

@import 'lib/slogan';
@import 'lib/image_gallery';
@import 'lib/link_boxes';
@import 'lib/accordeon_boxes';
@import 'lib/ehotelier_element';
@import 'lib/text_plugin';
@import 'lib/google_maps';
@import 'lib/video_element';
@import 'lib/popup';

/*
 * Getavo Fix
 */

.yanovis-voucher-preview img {
  width: auto;
}